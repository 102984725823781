import React from "react";
import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase";

// import "./Tags.scss";

const Tags = ({ item }) => {
  return (
    <div className="Tags">
      <p>
        Search by tags{" "}
        <span role="img" aria-label="search by tag">
          🎯
        </span>
      </p>
      <div className="tag">
        {item.map((item) => (
          <Link key={item.tag} to={`/tags/${kebabCase(item.tag)}/`}>
                        {item.tag}
            {/* </span> */}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Tags;

// import React from "react";

// import "./Tags.scss";

// const Tags = ({ item }) => {
//   return (
//     <div className="Tags">
//       <h1>Search by tags 🎯</h1>
//       <br />
//       <div className="tag">
//         {item.map((item) => (
//           <div>{item}</div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Tags;
