import React from "react";
import Layout from "../components/Layout";


import { graphql } from "gatsby";



import Tags from "/src/components/Blog/Tags.jsx";
import Posts from "/src/components/Blog/Posts";

import { Head } from "/src/components/Header/Head.jsx"

// import "/src/components/Blog/Tags.scss";
// import "/src/components/Blog/Posts.scss";

import './blog.scss'


// filter: { frontmatter: { type: { ne: "project" } } }
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            readtime
            tags
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    tagsGroup: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`;

const Blog = (props) => {
  const posts = props.data.allMarkdownRemark.edges;
  // const { currentPage, numPages } = props.pageContext;  //to remove the currentPage is never used
  const { numPages } = props.pageContext;

  return (
    <Layout>
      {/* <Head content={'Blog > Tags'} /> */}      {/* <Heading title={'Blog'} icon={'📖'} /> */}
      <Head content={'Blog'} />
      <section className="section__blog">
        <Posts posts={posts} numPages={numPages} />
        <Tags item={props.data.tagsGroup.group} />
      </section>
    </Layout>
  );
};
export default Blog;